import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel';

$(document).ready(function(){
    // 初期化関数
    function initializeSlickSlider() {
        $('.indexRankingSlider').slick({
            infinite: true,
            slidesToShow: 4,
            slidesToScroll: 1,
            dots: false,
            arrows: true,
            prevArrow: '<span class="arrowBtn Prev"><i class="fa-solid fa-arrow-left text-brown-light"></i></span>',
            nextArrow: '<span class="arrowBtn Next bg-brown-light"><i class="fa-solid fa-arrow-right text-white"></i></span>',
            responsive: [
                {
                    breakpoint: 1199.98,
                    settings: {
                        slidesToShow: 3,
                    }
                },{
                    breakpoint: 767.98,
                    settings: {
                        slidesToShow: 2,
                    }
                }
            ]
        });
    }

    // 初期スライダーの設定
    initializeSlickSlider();

    // ウィンドウのロードおよびリサイズ時の処理
    $(window).on('load resize', function() {
        if ($('.indexRankingSlider').hasClass('slick-initialized')) {
            $('.indexRankingSlider').slick('unslick');
        }
        initializeSlickSlider();
    });
});